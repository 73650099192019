import { Box } from 'theme-ui';
import FamilyPhoto from "../../../../src/components/familyPhoto";
import Form from "../../../../src/components/form";
import ricoh from "../../../../content/svg/ricoh.svg";
import * as React from 'react';
export default {
  Box,
  FamilyPhoto,
  Form,
  ricoh,
  React
};